.uq-modal-close-button {
  position: absolute;
  right: 2rem;
  top: 2rem;
  cursor: pointer;
  height: 1.5rem;
  width: 1.5rem;
  svg {
    max-width: 100%;
    max-height: 100%;
  }
  button {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    &:focus,
    &:hover,
    &:active {
      border: none;
    }
  }
  &:after {
    position: absolute;
    width: 100%;
    height: 100%;
    left: -1rem;
    top: -1rem;
    display: block;
    content: "";
    padding: 1rem;
    border-radius: 50rem;
    z-index: -1;
  }
  &:hover {
    &:after {
      background: #fafafa;
    }
  }
  &:focus,
  &:active {
    &:after {
      background: rgba(81, 36, 122, 0.1);
    }
  }
}
