@import '../../../coreStyles';

div.pointSelectionModal,
.itemDescriptionModal {
  @extend .bottomModal;
  left: 0;
  &.routeDetails {
    max-height: calc(100% - 115px);
    font-size: .875rem;
    bottom: 0;
  }
  .buttons {
    margin: 0;
    padding: 0;
  }
  .buttons {
    justify-content:flex-start;
    margin-top: -10px;
  }
  .modalCloseRoute {
    width: 15px;
    top: .5rem;
    right: 1rem;
    &:after {
      display: none;
    }
    &.modalCloseRouteLight {
      top: 1rem;
    }
    path {
      stroke: black;
    }
  }
  .headingRoute {
    padding: 0 0 20px;
    margin-bottom: 10px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.03);
  }
  .routeSummary {
    padding-bottom: 20px;
  }
  .routeSummary,
  .headingRoute {
    border-bottom: 1px solid rgba(0, 0, 0, 0.03);
  }
  .expandButton {
    width: 50px;
    height: 30px;
    position: absolute;
    top: 6px;
    left: 50%;
    transform: translateX(-50%);
    background: transparent;
    &:after {
      display: block;
      margin: auto;
      content: '';
      left: 0;
      top: 0;
      width: 24px;
      height: 4px;
      background: #e6e6e6;
      border-radius: 8px;
    }
  }
}
