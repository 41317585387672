@keyframes hideshow {
  0% {
    opacity: 0.75;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(2);
  }
}

.liveData > svg {
  animation: hideshow 3s linear infinite;
}

.liveDataCircle {
  position: relative;
  left: -8px;
}
