@import '../../../coreStyles';
@import '../wayFindingField/wayFindingField.module';

  .searchWay {
    margin: 0 0 0 40px;
    position: relative;
    .arrowLeftIcon,
    .swapIcon,
    .progressIcon div {
      color: white;
    }
    .swapIcon,
    .progressIcon {
      top: 15px;
      left: inherit;
      right: 8px;
      position: absolute;
    }
    .progressIcon {
      width: 25px;
      height: 25px;
      border: 2px solid white;
    }
    .progressIcon div{
      max-width: 100%;
      max-height: 100%;
    }
    .arrowLeftIcon{
      top: 21px;
    }
  }
  .fieldResults {
    border-radius: 8px;
    color: white;
    border: 1px solid white;
    padding: 5px 11px;
    width: calc(100% - 70px);
    .searchWayImpulse {
      background:  white;
      animation: pulse-black 2s infinite;
      &.searchWayImpulseBottom {
        &:before {
          border-top: 6px solid white;
        }
      }
    }
    p {
      margin: 5px 0;
      padding-left: 20px;
      font-size: 14px;
      position: relative;
      span {
        display: block;
        max-width: 95%;
        min-height: 12px;
        @extend .cutText;
      }
      em {
        z-index: 111;
        width: 8px;
        height: 8px;
        top: 3px;
        left: 0;
        margin: 0;
        &:after {
          background: $colours-primary;
          left: 2px;
          top: 2px;
        }
        &.searchWayImpulseBottom {
          &:before {
            border-top: 4px solid white;
            border-left: 4px solid transparent;
            border-right: 4px solid transparent;
            bottom: -3px;
            left: 0px;
          }
        }
      }
      &:first-child {
        font-size: 11px;
      }
    }
  }
