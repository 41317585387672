@import "../wayFindingField/wayFindingField.module";
@import '../../../coreStyles';

.pointSelectionModal {
  @extend .bottomModal;
  left: 0;
  z-index: 12;
  max-width: 100%;
  min-height: 100px;
  padding: 6px 16px 0;
  @media only screen and (min-width: $landscape-search-width) {
    min-height: auto;
    max-width: 300px;
    bottom: 30px;
    left: 50%;
  }

  .classNameCloseButton {
    width: 10px;
    height: 10px;
    top: 5px;
    right: 15px;
    &:after {
      top: 0;
      left: -10px;
      padding: 10px;
    }
  }
  .pointSelectionModalBody {
    padding: .3rem 0;
    .body2 {
      overflow: hidden;
      @extend .cutText;
      margin: 5px 0 15px;
    }
    p:first-child {
      max-width: 300px;
      @extend .cutText;
    }
  }
  p {
    margin: 0 0 .3rem;
  }
  .pointSelectionButtons {
    border-top: 1px solid rgba(0,0,0,.06);
    display: flex;
    justify-content: space-between;
    margin-top: .5rem;
    padding-top:  .5rem;
    .pointSelectionButton {
      font-weight: bold;
      font-size: 12px;
      position: relative;
      padding: 10px 0 10px 20px;
      display: block;
      cursor: pointer;
      &:first-child {
        margin-right: 30px;
      }
      &:hover {
        color: $colours-primary;
        .searchWayImpulse {
          background:  $colours-primary;
          animation: pulse-black 2s infinite;
          &.searchWayImpulseBottom {
            &:before {
              border-top: 6px solid $colours-primary;
            }
          }
        }
      }
    }
  }
}
.searchWayImpulse {
  left: 0;
  bottom: 12px;
  top: inherit;
  margin: 0;
  background: black;
  &.searchWayImpulseBottom {
    bottom: 13px;
    &:before {
      border-top: 6px solid black;
    }
  }
}