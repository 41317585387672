@import "../../coreStyles.scss";

.mazemapRoot {
  width: 100%;
  min-height: 100%;


  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  z-index: 1;
}

.mazemapRoot.desktop {
  width: $desktop-search-width;
}
