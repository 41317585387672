@import "../../colours";

.zLevelControl {
  width: 40px;
  height: 60px;
  border-radius: 8px;
  background-size: cover;
  font-family: Roboto;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
  border-bottom: 0 none;

  &::before {
    content: "Floor \A";
    color: $colours-primary;
    font-family: Roboto;
    font-size: 11px;
    text-decoration: none solid rgb(81, 36, 122);
    line-height: 24px;
    letter-spacing: 0.5px;
    margin-left: 6px;
  }
  .dropdown-icon {
    svg {
      color: transparent;
    }
  }

  select {
    width: 100%;
    height: 40px;
    text-align: center;
    text-align-last: center;
    -moz-text-align-last: center;
    padding-right: 0;
    padding-left: 0;
    color: white;
    background: $colours-primary;
    border-radius: 8px;
    background-size: cover;
    option {
      color: $colours-primary;
      background: $colours-textPrimary;
    }
  }

  /*Safari can't center select element*/
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      select {
        padding-left: 10px;
      }
    }
  }
}

.floorContainer {
  position: absolute;
  top: 32px;
  right: 16px;
}

.floorContainerMobile {
  position: fixed;
  right: 10px;
  top: 235px;
}
