@use "../../colours" as *;
@import "../../coreStyles.scss";
.mapDropdown {
  position: relative;
  z-index: 11;
  width: auto;
  max-width: 180px;
  margin-right: 5px;
  margin-left: 0;
  color: rgb(77, 77, 77);
  @media only screen and (min-width: 960px) {
    margin-left: 5px;
    margin-right: 0;
  }
}
.mapDropdownHeader {
  z-index: 14;
  position: relative;
  padding: 0 30px 0 12px;
  width: auto;
  line-height: 34px;
  height: 34px;
  display: inline-block;
  font-family: var(--global-font-family);
  font-size: 0.85rem;
  border-radius: 8px;
  cursor: pointer;
  box-shadow: 0 2px 6px 0 rgb(0, 0, 0, 0.2);
  background: white;
  color: $colours-primary;
  @extend .cutText;
  @media only screen and (max-width: 370px) {
    max-width: 100px;
  }
  &:after {
    position: absolute;
    content: "";
    right: 10px;
    top: 16px;
  }
  &.open {
    &:after {
      width: 0;
      height: 0;
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
      border-top: 4px solid purple;
    }
  }
  &.close {
    &:after {
      width: 0;
      height: 0;
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
      border-top: 4px solid rgba(0, 0, 0, 0.33);
    }
  }
}
.mapDropdownList {
  width: 180px;
  background: white;
  position: absolute;
  left: 0;
  top: 45px;
  overflow: auto;
  margin: 0;
  padding: 0;
  border: 1px solid #eee;
  border-radius: 8px;
  z-index: 14;
  font-family: var(--global-font-family);
  font-size: 14px;
  max-height: 220px;
  box-shadow: 0 2px 6px 0 rgb(0, 0, 0, 0.2);
  @media only screen and (min-width: 980px) {
    right: 0;
    left: inherit;
  }
  li {
    list-style-type: none;
    margin: 0;
    padding: 10px 12px;
    cursor: pointer;
    &:hover {
      background: rgba(0, 0, 0, 0.033);
    }
    &.active {
      background: rgba(0, 0, 0, 0.033);
    }
  }
}
