@use "space-constants" as core;
@import "./colours";

:root {
  --global-font-family: "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.loadingIndicator {
  text-align: center;
  display: block;
  margin: 0 auto;
  color: $colours-primary;
}

.cutText {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.primaryItemText {
  color: $colours-textDefault;
  letter-spacing: 0.5px;
  font-family: var(--global-font-family);
  font-display: swap;
  text-decoration: none solid rgb(0, 0, 0);
}

.secondaryItemText {
  color: $colours-textDefault;
  font-size: 12px;
  font-family: var(--global-font-family);
  font-display: swap;
  text-decoration: none solid rgba(0, 0, 0, 0.72);
  letter-spacing: 0.5px;
}

.icon {
  color: $colours-primary;
}

.result-icon-container {
  width: 70px;
}

.actionButton {
  height: 32px;
  padding: 0 12px;
  border-radius: 8px;
  background-color: #fafafa;
  background-size: cover;
  font-family: var(--global-font-family);
  font-display: swap;
  font-size: 14px;
  color: $colours-primary;
  text-decoration: none solid rgb(81, 36, 122);
  letter-spacing: 0.5px;
  text-transform: uppercase;
  &_selected {
    background-color:  $colours-primary;
    color: white;
    text-decoration: none solid rgb(255, 255, 255);
  }
  &:hover {
    background-color: $colours-primary;
    color: white;
  }
}

.uq-button {
  background-color: #1e72c6;
  border: 2px solid #1e72c6;
  border-radius: 0.25rem;
  box-sizing: border-box;
  color: white;
  cursor: pointer;
  display: inline-block;
  font-family: var(--global-font-family);
  font-display: swap;
  font-size: 1rem;
  font-weight: normal;
  line-height: normal;
  padding: 0.5rem 1.5rem;
  position: relative;
  text-align: center;
  text-decoration: none;
  &--link{
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    &:focus {
      outline: 1px dotted #212121;
      outline: 5px auto -webkit-focus-ring-color;
    }
  }
  &--purple {
    background-color: core.$uq-purple;
    border-color:  core.$uq-purple;
    color: white;

    &:hover, &:focus {
      background-color: darken(core.$uq-purple, 10%);
      border-color: darken(core.$uq-purple, 10%);
      color: white;
    }
  }
  &--small {
    font-size: .875rem;
    padding: .3rem 1rem;
  }
  &--outline {
    background-color: transparent;
    border-color: #51247a;
    color: #51247a;
  }
  &--tiny {
    font-size: .75rem;
    padding: .25rem .5rem;
  }
  &--text {
    background-color: transparent;
    border-color: transparent;
    color: core.$uq-purple;

    &:hover, &:focus {
      background-color: transparent;
      border-color: transparent;
      color:  darken(core.$uq-purple, 10%);
    }
  }
}


input[type="radio"],
input[type="checkbox"] {
  appearance: none;
  display: inline-block;
  border: core.$form-checkbox-border-width solid core.$form-border-color;
  cursor: pointer;
  height: core.$form-checkbox-size;
  width: core.$form-checkbox-size;
  vertical-align: top;
  position: absolute;


  &:focus {
    border-color: core.$link;
    box-shadow: 0 0 0 1px core.$link;
    outline: none;
  }

  + label {
    display: inline-block;
    font-size: core.$font-size;
    font-weight: core.$font-weight-normal;
    line-height: core.$line-height-h;
    margin-bottom: core.$space;
    padding-top: core.$space-compressed-xs;
    padding-left: core.$space-m;
    vertical-align: top;
  }

  &:checked {
    border-color: core.$uq-neutral-dark60;
    border-width: 2px;
    background-color: $colours-primary;
    &::after {
      border-radius: 50%;
      background-color: core.$uq-neutral-dark60;
      content: "";
      display: block;
      position: absolute;
      top: 3px;
      left: 3px;
      height: core.$form-checkbox-size - 10px;
      width: core.$form-checkbox-size - 10px;
    }
    &:focus {
     border-color: core.$link;
      box-shadow: 0 0 0 1px core.$link;
    }
  }
  &.uq-checkbox--map{
    margin-top: 5px;
    border-radius: 4px;
    & + span {
      color: #999;
      font-weight: core.$font-weight-medium;
      margin: 0;
      padding: 0 0 0 1.5rem;
      border: 2px solid transparent;
    }
    &:hover {
      border: 1px solid core.$uq-neutral-dark60;
      & + span {
        color: #999;
      }
    }
    &:focus {
      border: 1px solid core.$uq-neutral-dark60;
      box-shadow: none;
      outline: none;
      & + span {
        outline: 1px dotted #212121;
        outline: 5px auto -webkit-focus-ring-color;
      }
    }
    &:checked {
      background: $colours-primary;
      border-radius: 4px;
      border-width: 1px;
      box-shadow: none;
      &:focus {
        border-color: $colours-primary;
        box-shadow: none;
        outline: none;
      }
      & + span {
        color: $colours-primary;
      }
      &:after {
        background: none;
        border-radius: 4px;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M5 13.3 8.9 18 19 6' stroke='%23fff' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'%3e%3c/path%3e%3c/svg%3e");
      }
    }
  }

  &.uq-input--error {
    border-color: core.$alert;
    &:focus {
      border-color: core.$link;
      box-shadow: 0 0 0 1px core.$link;
    }

    &:checked {
      border-color: core.$alert;
      &::after {
        background-color: core.$alert;
      }
      &:focus {
        border-color: core.$alert;
        box-shadow: 0 0 0 1px core.$alert;
      }
    }
  }

  &[disabled] {
    cursor: not-allowed;

    + label {
      opacity: .5;
    }
  }
}


.uq-loading-spinner {
  color: core.$uq-purple;
  background-color: transparent;
  border-radius: 50%;
  height: core.$space-xxl;
  padding: core.$space-xs;
  overflow: hidden;
  width: core.$space-xxl;
  -webkit-mask-image: linear-gradient(transparent 50%,#000 0),linear-gradient(90deg,transparent 50%,#000 0);
  mask-image: linear-gradient(transparent 50%,#000 0),linear-gradient(90deg,transparent 50%,#000 0);
  -webkit-animation: pure-material-progress-circular 6s cubic-bezier(.3,.6,1,1) infinite;
  animation: pure-material-progress-circular 6s cubic-bezier(.3,.6,1,1) infinite;

  &::before {
    background-color: transparent;
    border: core.$space-xs solid transparent;
    border-top-color: core.$uq-purple;
    border-radius: 50%;
    box-sizing: border-box;
    content: "";
    display: block;
    height: 100%;
    margin-bottom: core.$space-xs;
    width: 100%;
    -webkit-animation: pure-material-progress-circular-pseudo .75s linear infinite alternate;
    animation: pure-material-progress-circular-pseudo .75s linear infinite alternate;
  }

  &--light {
    color: white;

    &::before {
      border-top-color: white;
    }
  }

  &--small {
    height: core.$space-m;
    width: core.$space-m;
    padding: core.$space-compressed-xs;

    &::before {
      border: core.$space-compressed-xs solid transparent;
      margin-bottom: core.$space-compressed-xs;
    }
  }
}


@-webkit-keyframes pure-material-progress-circular {
  0% {
    transform: rotate(0deg)
  }
  12.5% {
    transform: rotate(180deg);
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear
  }
  25% {
    transform: rotate(630deg)
  }
  37.5% {
    transform: rotate(810deg);
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear
  }
  50% {
    transform: rotate(1260deg)
  }
  62.5% {
    transform: rotate(4turn);
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear
  }
  75% {
    transform: rotate(1890deg)
  }
  87.5% {
    transform: rotate(2070deg);
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear
  }
  to {
    transform: rotate(7turn)
  }
}

@keyframes pure-material-progress-circular {
  0% {
    transform: rotate(0deg)
  }
  12.5% {
    transform: rotate(180deg);
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear
  }
  25% {
    transform: rotate(630deg)
  }
  37.5% {
    transform: rotate(810deg);
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear
  }
  50% {
    transform: rotate(1260deg)
  }
  62.5% {
    transform: rotate(4turn);
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear
  }
  75% {
    transform: rotate(1890deg)
  }
  87.5% {
    transform: rotate(2070deg);
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear
  }
  to {
    transform: rotate(7turn)
  }
}

@-webkit-keyframes pure-material-progress-circular-pseudo {
  0% {
    transform: rotate(-30deg)
  }
  29.4% {
    border-left-color: transparent
  }
  29.41% {
    border-left-color: currentColor
  }
  64.7% {
    border-bottom-color: transparent
  }
  64.71% {
    border-bottom-color: currentColor
  }
  to {
    border-left-color: currentColor;
    border-bottom-color: currentColor;
    transform: rotate(225deg)
  }
}

@keyframes pure-material-progress-circular-pseudo {
  0% {
    transform: rotate(-30deg)
  }
  29.4% {
    border-left-color: transparent
  }
  29.41% {
    border-left-color: currentColor
  }
  64.7% {
    border-bottom-color: transparent
  }
  64.71% {
    border-bottom-color: currentColor
  }
  to {
    border-left-color: currentColor;
    border-bottom-color: currentColor;
    transform: rotate(225deg)
  }
}

$transition: .2s;
.bottomModal {
  top: inherit;
  bottom: 0;
  width: calc(100% - 32px);
  min-width: auto;
  max-width: initial;
  background: white;
  max-height: 200px;
  z-index: 11;
  transform: none;
  border-radius: 8px;
  padding: 0 16px;
  -webkit-transition: all .5s ease;
  -moz-transition: all .5s ease;
  transition: max-height $transition;
}

.body1,
.body2 {
  letter-spacing: 0.5px;
  font-family: var(--global-font-family);
  font-display: swap;
}
.body1 {
  color: $colours-textDefault;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.3;
}
.fs18 {
  font-size: 18px;
}
.fs16 {
  font-size: 16px;
}
.body2 {
  color: $colours-textSecondary;
  font-size: 12px;
}
.body3 {
  color:rgba(153,153,153);
  font-size: 14px;
  line-height: 1.5rem;
}
.m0 {
  margin: 0;
}

// these widths must be in pixels for mapbox padding calculations.
$desktop-search-width: 384px;
$mobile-search-width: 600px;
$landscape-search-width: 760px;
$tablet-search-width: 960px;


$shadow: 0px 4px 8px rgba(0, 0, 0, 0.08), 0px 8px 16px rgba(0, 0, 0, 0.16);

// Hide mapbox links so they users don't click on these links by mistake.
.mapboxgl-ctrl-attrib.mapboxgl-compact, .mapboxgl-ctrl-attrib-inner, div.mapboxgl-ctrl, a.mapboxgl-ctrl-logo {
  display: none !important;
}

