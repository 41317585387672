.uq-modal-overlay {
  position: fixed;
  z-index: 9999998;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;

  &--light {
    background: hsla(0, 0%, 100%, .8);
  }

  &--dark {
    background: rgba(0, 0, 0, 0.8);
  }
  &--transparent {
    background: none;
  }
}