@import '../../../coreStyles';
@import '../../wayFinding/mobileRoutePanel/mobileRoutePanel.module.scss';

.itemDescriptionModal {
  @extend .bottomModal;
  left: 0;
}
.itemDescriptionFullHeightModal {
  @extend .bottomModal;
  height: 100%;
  left: 0;
  padding: 0;
  width: 100%;
  max-height: inherit;
  font-size: 1rem;
}
.pointSelectionModalBody {
  padding: 1rem 0 0;
}

.hidden {
  display: none;
}

.arrowLeftIcon {
  transform: scale(1.1);
  position: absolute;
  left: 1rem;
  top: 1rem;
  z-index: 111;
  &:after {
    @media only screen and (max-width: $landscape-search-width) {
      position: absolute;
      left: -3px;
      top: -3px;
      width: 40px;
      height: 40px;
      background: black;
      border-radius: 100px;
      opacity: .4;
      z-index: 11;
      content: '';
    }
  }

  svg {
    stroke-width: 3px;
    width: 22px;
    height: 18px;
    color: $colours-primary;
    @media only screen and (max-width: $landscape-search-width) {
      position: relative;
      z-index: 111;
      left: 7px;
      top: 7px;
    }
    * {
      stroke: white;
    }
  }
  &:before {
    border-width: thick;
  }
}