@use "../../colours" as *;
@import "../../coreStyles";


.labelStart{
  display: block;
  font-weight: 500;
  font-size: 1rem;
  margin: 0 0 .3rem !important;
  padding: 0;
  svg {
    font-size: 1.5rem;
  }
}
.mapModal {
  width: 32rem;
  font-family: var(--global-font-family);
  .label,
  form label {
    @extend .labelStart;
  }
  input[type=radio] {
    & + label {
      @extend .labelStart;
    }
  }
  &.settingsModal {
    height: 50%;
    overflow: auto;
    width: auto;
  }
  .mapModalBody {
    padding: 2rem 1.5rem 2rem;
    .mapModalBodySection {
      margin-bottom: 1rem;
      line-height: 2rem;
    }

    // TODO remove when create global select component
    select {
      appearance: none;
      background: url("../../assets/chevron_down.svg") no-repeat right 1rem center;
      cursor: pointer;
      line-height: 1.2;
    }
    // TODO remove when create global select component
    input:not([type="checkbox"]):not([type="radio"]):not([type="submit"]),
    select,
    textarea {
      background-color: #fff;
      padding: 0.75rem 1rem;
      border: 1px solid #e7e3e0;
      border-radius: 2px;
      font-size: 1rem;
      color: #2b2a29;
      line-height: 1;
      margin-bottom: 1.5rem;
      display: block;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      width: 100%;
      font-family: var(--global-font-family);
    }
    textarea {
      height: 5rem;
    }
    .submitButton {
      padding: 0.5rem 1.5rem;
      margin-top: 1rem;
    }
  }
}


.settingsWrap {
  padding: 20px;
  .settingsList {
    padding: 0;
    margin: 0;

    .settingsListItem {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
}


.react-switch-checkbox {
  height: 0;
  width: 0;
  visibility: hidden;
}

.react-switch-label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: 100px;
  height: 50px;
  background: grey;
  border-radius: 100px;
  position: relative;
  transition: background-color .2s;
}

.react-switch-label .react-switch-button {
  content: '';
  position: absolute;
  top: 2px;
  left: 2px;
  width: 45px;
  height: 45px;
  border-radius: 45px;
  transition: 0.2s;
  background: #fff;
  box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
}

.react-switch-checkbox:checked + .react-switch-label .react-switch-button {
  left: calc(100% - 2px);
  transform: translateX(-100%);
}

.react-switch-label:active .react-switch-button {
  width: 60px;
}