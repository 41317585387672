@import '../../../coreStyles';

.primaryItemTitle {
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.6rem;
  margin: 0 0 .5rem;
  padding: 0;
}
.primaryItemName {
  color: #565452;
  font: .85rem/1.2rem var(--global-font-family);
}