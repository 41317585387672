.uq-modal-content {
  position: absolute;
  z-index: 9999999;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  background: white;
  overflow: auto;
  border-radius: 4px;
  outline: none;

  min-width: 200px;
  max-width: 800px;
  &--light {
    box-shadow: 0 0 0.5rem rgb(204, 204, 204);
  }
  @media only screen and (max-width: 600px) {
    width: auto;
    max-width: calc(100% - 2rem);
  }
}
