@import '../../../coreStyles';

.itemDescriptionButtons {
  margin-top: 20px;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
  margin: 24px -24px;
  padding: 24px 24px;
  display: flex;
  align-items: flex-end;
  font-size: 0.8rem;
}
.defaultButton {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  &:focus,
  &:hover,
  &:active {
    border: none;
  }
}

.descriptionButton {
  @extend .defaultButton;
  text-align: center;
  color: $colours-primary;
  margin-right: 30px;
  text-decoration: none;
  &:focus {
    outline: 2px solid #005ea5;
  }
}
.directionButton {
  @extend .descriptionButton;
  span {
    &:first-child {
      background: $colours-primary;
    }
  }
}
.outlinedButton {
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  border: 1px solid $colours-primary;
  margin: 0 auto 0.5rem;
  svg {
    font-size: 1.2rem;
    width: 20px;
    height: 20px;
  }
  &.purpleSvg {
    * {
      stroke: $colours-primary;
    }
  }
}

.descriptionButton {
  @extend .defaultButton;
  text-align: center;
  color: $colours-primary;
  margin-right: 30px;
  text-decoration: none;
}

.hidden {
  display: none;
}

.shareLink {
  svg {
    width: 16px;
    height: 16px;
    * {
      stroke: $colours-primary;
    }
  }
}