@import "../../coreStyles";

.mapControlsIcons,
.mapControlsDropdowns {
  display: flex;
}

.mapControls {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0 12px;
  top: 78px;
  @media only screen and (min-width: $landscape-search-width) {
    top: 12px;
    justify-content: flex-end;
  }
}
.mapControlsDropdowns{
  @media only screen and (min-width: $landscape-search-width) {
    order:2;
  }
}
.mapControlsIcons {
  flex-direction: column;
  @media only screen and (min-width: $landscape-search-width) {
    flex-direction: unset;
    order:1;
  }
}

.basicWhiteWrap {
  position: relative;
  background: white;
  height: 34px;
  width: 44px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5px;
  box-shadow: 0 2px 6px 0 rgb(0, 0, 0, 0.2);
  button {
    position: absolute;
    height: 20px;
  }
  @media only screen and (min-width: $landscape-search-width) {
    margin-bottom: 0;
    margin-left: 5px;
  }
}
.rotateControlWrap {
  @extend .basicWhiteWrap;
  button {
    width: 16px;
    height: 20px;
  }
  .rotateControl {
    transition:transform $transition ease-in-out;
  }
}
.layerControlWrap {
  @extend .basicWhiteWrap;
  button {
    width: 20px;
  }
}
.gpsControlWrap {
  @extend .basicWhiteWrap;
  button {
    width: 16px;
    left: 13px;
  }
}
