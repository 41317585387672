@import '../../../colours';

.searchFieldWrap {
  position: relative;
  margin-top: -3px;

  .searchWayField {
    -webkit-appearance: none;
    border-radius: 8px;
    height: 38px;
    width: calc(100% - 70px);
    box-sizing: content-box;
    margin: 0 0 12px 30px;
    padding: 0 30px 0 10px;
    border: 1px solid #e7e3e0;
  }
}


.fieldIcons {
  display: block;
  right: 0;
  top: 6px;
  position: absolute;

  div {
    animation: none;
  }
  button {
    display: flex;
  }
  .searchIcon {
    transform: scale(.6);
  }
  .progressIcon {
    transform: scale(.4);
    position: relative;
    right: 5px;
    &:before {
      border-width: thin;
    }
  }
  .closeIcon {
    transform: scale(.4);
  }
}



.searchWayImpulse{
  display: block;
  border-radius: 50%;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
  margin: 13px 12px 12px 8px;
  height: 12px;
  width: 12px;
  transform: scale(1);
  position: absolute;
  top: 0;
  background:$colours-primary;
  &.searchWayImpulseBottom{
    &:before {
      position: absolute;
      content: '';
      width: 0;
      height: 0;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-top: 6px solid $colours-primary;
      bottom: -2px;
      left: 0;
    }
  }

  &:after {
    position: absolute;
    width: 4px;
    height: 4px;
    left: 4px;
    top: 4px;
    content: '';
    border-radius: 50%;
    background: white;
  }
}
.active {
  &.searchWayImpulse {
    background: $colours-primary;
    animation: pulse-black 2s infinite;
  }
}
@keyframes pulse-black {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba($colours-primary, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba($colours-primary, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba($colours-primary, 0);
  }
}