@use '../../../colours' as *;
@import '../../../coreStyles.scss';

.searchFieldWrap{
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08), 0px 4px 8px rgba(0, 0, 0, 0.16);
  border-radius: 8px;
  background-color: $colours-backgroundDefault;
  position: relative;
  padding: 0;
  align-items: center;
  display: flex;
  height: 55px;
  .searchFieldLeftArea {
    width: 55px;
    height: 100%;
    line-height: 1rem;
    display: flex;
    align-self: center;
    justify-content: center;
    .arrowLeftIcon {
      transform: scale(0.9);
      color: $colours-primary;
      svg {
        stroke-width: 3px;
        width: 22px;
        height: 18px;
      }
    }
    .locationIcon {
      transform: scale(1.2);
      &:focus {
        outline: none;
      }
    }
  }
  .searchFieldRightArea{
    display: flex;
    justify-content: center;
    align-items: center;
    width: auto;
    position: relative;
    .directionWrap:before {
      position: absolute;
      content: '';
      width: 1px;
      height: 30px;
      display: block;
      border-left: 2px solid #f5f5f5;
      top: 0;
      left: 48%;
      z-index: 1;
    }
    .searchWrap,
    .directionWrap{
      width: 45px;
      height: 30px;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      button {
        display: flex;
      }
    }
    .searchIcon {
      svg {
        color: $colours-primary;
        width: 23px;
        * {
          stroke: $colours-primary;
        }
      }
    }
    .directionsIcon {
      transform: scale(1);
      svg {
        color: $colours-primary;
        min-width: 25px;
        * {
          stroke: $colours-primary;
        }
      }
    }
    .progressIcon {
      transform: scale(.5);
      &:before {
        border-width: thin;
      }
    }
    .closeIcon {
      transform: scale(.6);
    }
  }
  .searchFieldText {
    border: 1px solid transparent;
    width: calc(100% - 36px);
    font-family: var(--global-font-family);
    font-size: .9rem;
    padding: 14px 5px;
    &:focus {
      outline: none;
    }
  }
}
.searchLocation {
  width: 20px;
}
