.mapLayerRadioLine {
  width: 100%;
  input[type="radio"] {
    opacity: 0;
    position: fixed;
    width: 0;
    &:checked {
      & + label {
        background-color: rgba($color: #51247a, $alpha: 0.1);
        background-size: cover;
        &:after {
          background: url("../../assets/RadioChecked.svg") no-repeat left top;
        }
      }
    }
    + label {
      display: block;
      background-color: #fafafa;
      padding: 1rem 1rem 1rem 3.3rem;
      margin-bottom: 0.5rem;
      border-radius: 0.5rem;
      position: relative;
      cursor: pointer;
      &:after {
        position: absolute;
        left: 1rem;
        top: 1rem;
        content: "";
        width: 2rem;
        height: 2rem;
        background: url("../../assets/RadioUnChecked.svg") no-repeat left top;
      }
    }
  }

}
.mapLayerModal {
  width: 18rem;
  .mapLayerModalBody {
    padding: 2rem 1.5rem 5rem;
  }
}
