@import '../../../coreStyles';

.itemDescriptionWrap {
  height: 100%;
  padding: 0 24px 24px;
  width: calc(100% - 48px);
  max-height: 100%;
  overflow-y: auto;
}

.itemDescriptionImage {
  height: 210px;
  width: calc(100% + 48px);
  position: relative;
  overflow: hidden;
  margin: 0 -24px 24px;
  img {
    position: absolute;
    left: 0;
    bottom: 0;
  }
}
.descriptionItemText {
  font: .85rem/1.2rem var(--global-font-family);
}

.overviewOutlined {
  border-bottom: 3px solid $colours-primary;
  margin: 0 -24px 24px;
  padding: 12px 24px 12px;
  font-weight: 500;
}