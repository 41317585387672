//@use '../../../colours' as *;
@import '../../../coreStyles';
@import '../../../components/Tabs/Tabs';


.routeDetailsListItem {
  line-height: 1.5;
  .body2 {
    display: block;
    margin: 15px 0;
    @extend .cutText;
  }
 }
.routeDetailDots {
  @extend .icon;
  color: $colours-primary;
  max-height: 16pt;
  width: 20pt;
  overflow: hidden;
  padding: 0;
  margin: 5px 0;
  .routeDetailsIcon{
    max-width: 100%;
    max-height: 100%;
    position: relative;
    top: -5px;
  }
}
.routeDetailsDescription {
  flex-direction: column;
  display: flex;
  padding: 0 0 10px;
  margin: 0;
  span{
    &:first-child {
      margin-bottom: 10px;
      display: block;
    }
  }
}
.routeDetailsListItem {
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
}
.routeDetailsIconContainer {
  padding-right: 16px;
  display: flex;
}
.tabWrap {
  padding-top: 30px;
  height: 100%;
  max-height: 100%;
  overflow-y: scroll;
}

.tabs {
  display: flex;
  list-style: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.03);
  margin: 0;
  padding: 0;
  li {
    width: 50%;
    flex-grow: 1;
    max-width: none;
    flex-basis: 0;
    flex-shrink: 1;
    height:60px;
    line-height: 60px;
    border-bottom: 3px solid transparent;
    cursor: pointer;
    padding: 0;
    &.active {
      border-bottom: 3px solid  $colours-primary;
      font-weight: 500;
    }
  }
}
.body3{
   line-height: 1.3rem;
   font-size: .85rem;
   margin: 0 0 15px;
 }
.instructions {
  height: 100%;
  max-height: 100%;
}