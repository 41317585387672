@import "../../coreStyles";

.mapControls {
  display: flex;
  align-items: initial;
  @media only screen and (min-width: 980px) {
    width: auto;
    position: absolute;
    top: 12px;
    right: 12px;
  }
}

.mapControlsIcons,
.mapControlsDropdowns {
  display: flex;
}

.mapControlsDropdowns {
  position: absolute;
  left: 12px;
  margin-right: 60px;
  z-index: 1;
  @media only screen and (min-width: 980px) {
    position: initial;
    top: initial;
    right: initial;
    width: auto;
    margin-right: 0;
  }
}
.mapControlsIcons,
.mapControlsDropdowns{
  top: 78px;
  @media only screen and (min-width: $landscape-search-width) and (max-width: $tablet-search-width) {
    top: 12px;
    right: 0;
    left: inherit;
  }
  &.controlsIconsWayFinding {
    top: 12px;
  }
}
.lightView {
  .mapControlsIcons,
  .mapControlsDropdowns{
    @media only screen and (max-width: $landscape-search-width) {
      display: none;
    }
  }
  .mapControlsDropdowns{
    left: 12px;
  }
}

.mapControlsIcons {
  flex-direction: column;
  position: absolute;
  right: 12px;
  > button,
  > div {
    margin-bottom: 5px;
    @media only screen and (min-width: 980px) {
      margin-bottom: 0;
    }
  }
  @media only screen and (min-width: $landscape-search-width) {
    top: 12px;
  }
  @media only screen and (min-width: 980px) {
    flex-direction: row;
    position: initial;
    right: initial;
    top: initial;
  }

}

button.layerControl,
button.rotateControl,
.layerControl {
  background: white;
  width: 40px;
  border-radius: 8px;
  cursor: pointer;
  padding: 0;
  height: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 2px 6px 0 rgb(0, 0, 0, 0.2);
  text-align: center;
  border: 1px solid white;
  @media only screen and (min-width: 980px) {
    margin-left: 6px;
  }
  &.layerControlNoBackground {
    background: none;
    box-shadow: none;
    border: none;
    margin: 0;
  }
}

.rotateControl {
  height: 18px;
  transition:transform $transition ease-in-out;
}
.starIcon{
  svg {
    width: 22px;
    height: 22px;
    display: block;
    fill:  rgba(0, 0, 0, 0.26);
  }
  &.filled {
    svg {
      fill:  orange;
    }
  }
}
