@use '../../../colours' as *;
@import '../../../coreStyles.scss';

.isNotSearching {
  height: 0;
  overflow: hidden;
  padding-top: 0;
}
.resultsListWrapper {
  height: 100%;
  overflow-y: scroll;
}
.resultsList {
  list-style-type: none;
  justify-content: space-between;
  display: flex;
  flex-wrap: wrap;
  padding: 0 0 20px;
  margin: 0;
  text-align: center;

  .preSelectedLocation {
    position: relative;
    padding: 12px 15px;
    display: block;
    flex: 0 0 100%;
    text-align: initial;

    &:before {
      position: absolute;
      content: '';
      border-bottom: 1px solid rgba(0, 0, 0, 0.03);
      width: 75%;
      height: 100%;
      bottom: 0;
      left: 50px;
    }

    svg {
      width: 30px;
      position: relative;
      top: 10px;
      margin: 0 10px 0 0;
    }
  }

  li {
    font-style: normal;
    position: relative;
    text-align: center;
    padding: 0;
    margin: 0;

    &:hover {
      cursor: pointer;
    }
  }

  .categoryItem {
    padding: 20px 14px 0;
    list-style: none;
    flex: 0 0 calc(33.3333% - 28px);
    text-align: center;

    &:hover {
      .categoryPicture {
        background: #e8e8e8;
        transition: background-color .2s ease;
      }
    }

    &.categoryItemNoActive {
      cursor: default;

      &:hover {
        .categoryPicture {
          background: #f6f6f6;
        }
      }
    }
  }

  .divider {
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);
    display: flex;
    flex: 0 0 100%;
    margin: 20px 0 0;
  }

  .poiItem {
    margin: 0;
    flex: 0 0 100%;
    padding: 12px 0;
    display: flex;
    align-items: center;
    line-height: 1.3rem;
    text-align: left;
    position: relative;
    overflow: hidden;
    &.pulsate {
      animation-name: color_change;
      animation-duration: .6s;
      animation-iteration-count: infinite;
      animation-direction: alternate;
    }
    @-webkit-keyframes color_change {
      from { color:  $colours-primary; }
      to { color: white; }
    }
    @-moz-keyframes color_change {
      from { color:  $colours-primary; }
      to { color: white; }
    }
    @-ms-keyframes color_change {
      from { color:  $colours-primary; }
      to { color: white; }
    }
    @-o-keyframes color_change {
      from { color:  $colours-primary; }
      to { color-color: white; }
    }
    @keyframes color_change {
      from { color:  $colours-primary; }
      to { color: white; }
    }

    &:before {
      position: absolute;
      content: '';
      border-bottom: 1px solid rgba(0, 0, 0, 0.03);
      width: 85%;
      height: 100%;
      bottom: 0;
      left: 50px;
      @media only screen and (min-width: $landscape-search-width) {
        width: 75%;
      }
    }

    &:after {
      position: absolute;
      border-left: 3px solid transparent;
      height: 100%;
      width: 3px;
      content: '';
      display: block;
      left: 0;
      top: 0;
      z-index: 11;
    }

    &:hover,
    &:active,
    &:focus {
      background: rgba(0, 0, 0, 0.03);

      &:after {
        border-left: 3px solid purple;
      }
    }

    .poiItemText {
      font-style: normal;
      max-width: 80%;
      @media only screen and (min-width: $landscape-search-width) {
        max-width: 100%;
      }

      p {
        line-height: 1.2;
        margin: .3rem 0 0;
      }

      b {
        font-weight: 500;
      }

      em {
        font-style: normal;
      }
    }
  }

  .poiPicture {
    margin: 12px 12px 12px 18px;
    min-width: 27px;
    min-height: 27px;
    text-align: center;
  }
}
.noResults {
  text-align: center;
}
.secondaryItemText {
  color: rgba(0,0,0,0.6);
  margin: 0;
}
.poiName,
.secondaryItemText {
  width: 100%;
  @extend .cutText;
  @media only screen and (min-width: $landscape-search-width) {
    width: 300px;
  }
}

.categoryPicture {
  background: #f6f6f6;
  border-radius: 50rem;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 10px;
}
.categoryListItemText {
  font-family: var(--global-font-family);
  font-size: .83rem;
  line-height: 1.1rem;
}
.searchResults {
  .progressIcon {
    &:before {
      border-width: 2px;
    }
  }
}

.defaultButton {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  &:focus,
  &:hover,
  &:active {
    border: none;
  }
}

.outlinedButton {
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  background-color: #f6f6f6;
  margin: 0 auto 0.6rem;
  svg {
    font-size: 1.2rem;
    width: 20px;
    height: 20px;
  }
  &.purpleSvg {
    * {
      stroke: $colours-primary;
    }
  }
}

.descriptionButton {
  @extend .defaultButton;
  text-align: center;
  color: $colours-primary;
  margin: 0 25px;
  text-decoration: none;
}

.hidden {
  display: none;
}

.shareLink {
  svg {
    width: 18px;
    height: 18px;
    * {
      stroke: $colours-primary;
    }
  }
}