@use './colours' as *;
@import './coreStyles';
@import 'features/wayFinding/wayFinding/wayFinding.module';
@import 'features/wayFinding/pointSelection/pointSelection.module';

.mapsApp {
  height: 100%;
  :global(#map1) {
    isolation: isolate;
  }
  .slidePaneButton {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08), 0px 4px 8px rgba(0, 0, 0, 0.16);
    left: $desktop-search-width;
    top: 50%;
    margin-left: 0;
    position: absolute;
    background: white;
    border-radius: 0 6px 6px 0;
    width: 20px;
    height: 40px;
    display: none;
    border: 2px solid white;
    @media only screen and (min-width: $mobile-search-width) {
      display: block;
    }
    &:after,
    &:before {
      position: absolute;
      content: '';
      top: 12px;
      left: 2px;
      width: 0;
      height: 0;
      border-top: 6px solid transparent;
      border-bottom: 6px solid transparent;
    }
    &:before {
      border-left: 6px solid purple;
      display: none;
    }
    &:after {
      border-right: 6px solid purple;
    }
    &:hover,
    &:active {
      opacity: .7;
      border: 2px solid white;
      outline: 2px solid white;
    }
    &.hide {
      left: 0;
      &:after {
        display: none;
      }
      &:before {
        display: block;
      }
    }
  }
  .searchPane {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    z-index: 2;
    background: white;
    max-width: $landscape-search-width;
    width: 100%;
    @media only screen and (min-width: $landscape-search-width) {
      max-width: $desktop-search-width;
    }
    &.hide {
      left: -($landscape-search-width);
      @media only screen and (min-width: $landscape-search-width) {
        left: -($desktop-search-width);
      }
    }
    &.wayFindingLightViewPane {
      left: -($landscape-search-width);
    }
  }


  .searchContainer {
    z-index: 2;
    pointer-events: none;
    position: absolute;
    & > * {
      pointer-events: auto;
    }
  }
  .searchFinding {
    position: absolute;
    z-index: 9;
    left: 12px;
    top: 12px;
    border-radius: 8px;
    background: white;
    height: 54px;
    max-width: calc(#{$landscape-search-width} - 24px);
    width: calc(100% - 24px);
    @media only screen and (min-width: $landscape-search-width) {
      max-width: calc(#{$desktop-search-width} - 24px);
    }
    &.hide {
      left: 12px;
      @media only screen and (min-width: $tablet-search-width) {
        left: -($desktop-search-width);
      }
    }
    &.searchWayFinding {
      padding: 12px 0 0 12px;
      max-width: calc(#{$landscape-search-width} - 12px);
      width: calc(100% - 12px);
      left: 0;
      top: 0;
      height: 135px;
      border-radius: 0 0 8px 8px;
      @media only screen and (min-width: $landscape-search-width) {
        max-width: calc(#{$desktop-search-width} - 12px);
      }
      &.hide {
        left: -($landscape-search-width);
        @media only screen and (min-width: $landscape-search-width) {
          max-width: calc(#{$desktop-search-width} - 12px);
        }
      }
      &:after,
      &:before {
        position: absolute;
        content: '';
        height: 15px;
        background: white;
        left: 0;
        width: 100%;
        @media only screen and (min-width: $landscape-search-width) {
          width: $desktop-search-width;
        }
      }
      &:before {
        bottom: 10px;
        height: 10px;
        z-index: 2;
      }
      &:after {
        bottom: 0;
        border-radius: 8px;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08), 0px 4px 8px rgba(0, 0, 0, 0.16);
      }
    }
    &.wayFindingLightView {
      background: $colours-primary;
      padding-top: 15px;
      height: 94px;
      &.hide {
        left: 0;
      }
      &:after,
      &:before{
        display: none;
      }
    }
  }
  .feedbackButton {
    background-color: white;
    bottom: 20px;
    box-sizing: border-box;
    padding: 14px;
    position: absolute;
    margin: 14px;
    right: 20px;
    border-radius: 100px;
    line-height: 1rem;
    cursor: pointer;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08), 0px 4px 8px rgba(0, 0, 0, 0.16);
    &:hover {
      background-color: #f5f5f5;
    }
    svg {
      width: 28px;
      height: 28px;
    }
  }
}

button.viewIcon {
  background: white;
  white-space: nowrap;
  border-radius: 8px;
  cursor: pointer;
  padding: 4px 8px;
  box-shadow: 0 2px 6px 0 rgb(0, 0, 0, 0.2);
  text-align: center;
  border: 1px solid white;
  color: $colours-primary;
  font-size: 12px;
  position: fixed;
  right: 12px;
  bottom: 8px;
  z-index: 111;
  line-height: 1rem;
  display: none;
  align-items: center;
  .mapIcon {
    height: 18px;
  }
  .listIcon {
    height: 16px;
  }
  &:hover,
  &:focus {
    outline: none;
  }
  @media only screen and (max-width: $landscape-search-width) {
    display: flex;
  }
}

.stage {
  position: absolute;
  bottom: 50px;
  left: 5px;
  z-index: 1500;
}

.progressIcon {
  width: 100%;
  display: flex;
  justify-content: center;
  transform: scale(.5);
}
.searchResults {
  position: relative;
  z-index: 11;
  box-sizing: border-box;
  width: 100%;
  background-color: $colours-backgroundDefault;
  font: .85rem/1.5rem var(--global-font-family);
  padding: 0;

  height: calc(100% - 68px);
  top: 68px;
  @media only screen and (min-width: $tablet-search-width) {
    top: 75px;
    height: calc(100% - 75px);
  }

  &.searchResultsWayFinding {
    top: 165px;
    height: calc(100% - 165px);
    @media only screen and (min-width: $tablet-search-width) {
      top: 150px;
    }
  }

  .loadingButtonWrap {
    height: 50%;
    min-height: 50%;
    display: flex;
    align-items: center;
    .loadingIndicator {
      height: 50px;
      width: 50px;
      display: block;

      &:before {
        border-width: thin;
      }
    }
  }
}
.arrowLeftIcon {
  width: 36px;
  transform: scale(0.9);
  position: absolute;
  left: 13px;
  top: 30px;
  svg {
    stroke-width: 3px;
    width: 22px;
    height: 18px;
    color: $colours-primary;
  }
  &:before {
    border-width: thick;
  }
}
.wayFindingLightView {
  .arrowLeftIcon {
    top: 27px;
    svg {
      * {
        stroke: white;
      }
    }
  }
}

.routeDetails {
  margin-top: 10px;
  padding: 20px 23px;
  height: calc(100% - 200px);
  .summaryShare {
    padding-right: 15px;
  }
}
