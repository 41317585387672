@use "../../colours" as *;
@import "../../coreStyles";

.copyrightPanel {
  position: absolute;
  right: auto;
  left: 0;
  bottom: 0;
  opacity: 1;
  padding: 4px 8px;
  background-color: white;
  font-family: var(--global-font-family);
  font-size: 10px;
  @media only screen and (min-width: 980px) {
    right: 120px;
    left: auto;
    font-size: 12px;
  }
  & .sep {
    padding: 0 3px;
    @media only screen and (min-width: 980px) {
      padding: 0 8px;
    }
  }
  & .link {
    color: #222;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
}
