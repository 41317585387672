.uq-modal-footer {
  padding: 1.5rem 0 0;
  display: flex;
  justify-content: flex-end;
  &--left {
    justify-content: start;
  }
  &--center {
    justify-content: center;
  }
}
