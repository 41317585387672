
@import '../../../coreStyles';

.routeWrap {
  display: flex;
  justify-content: space-between;
  padding:10px 0 20px 0;
  .body3 {
    color: black;
  }
}

.shareButton {
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 100px;
  background-color: #f6f6f6;
  margin: 0 0 0 0.6rem;
  cursor: pointer;
  svg {
    font-size: 1.2rem;
    width: 20px;
    height: 20px;
  }
  &.purpleSvg {
    * {
      stroke: $colours-primary;
    }
  }
}

.shareIcon {
  svg {
    width: 16px;
    height: 16px;
    * {
      stroke: $colours-primary;
    }
  }
}