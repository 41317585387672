.app {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  
  .main {
    flex-grow: 1;
    min-height: 0;

    position: relative;
  }
}
