// Space is calculated as multiples of eight 
// Baseline is default of device or broswer, typically 16px

$space-uuuul: 10rem; /* 160px */ 
$space-uuul: 5.5rem; /* 88px */
$space-uul: 5rem; /* 80px */
$space-ul: 4.5rem; /* 72px */
$space-xxxxl: 4rem; /* 64px */
$space-xxxl: 3.5rem; /* 56px */
$space-xxl: 3rem; /* 48px */
$space-xl: 2.5rem; /* 40px */
$space-l: 2rem; /* 32px */
$space-m: 1.5rem; /* 24px */
$space: 1rem; /* 16px */ /* Default */
$space-s: 0.5rem; /* 8px */ 
$space-xs: 0.25rem; /* 4px */
/* 2px (0.125rem) */
/* 1px (0.0625rem) */
$space-none: 0; /* zero - no spacing */
$space-auto: auto; /* auto */



/* Conceptual spacing options - use with caution as these may change. */ 

/* compressed - half the original value */
$space-compressed-uuuul: 5rem; /* 80px (160px) */
$space-compressed-uuul: 2.75rem; /* 44px (88px) */
$space-compressed-uul: 2.5rem; /* 40px (80px) */
$space-compressed-ul: 2.25rem; /* 36px (72px) */
$space-compressed-xxxxl: 2rem; /* 32px (64px) */
$space-compressed-xxxl: 1.75rem; /* 28px (56px) */
$space-compressed-xxl: 1.5rem; /* 24px (48px) */
$space-compressed-xl: 1.25rem; /* 20px (40px) */
$space-compressed-l: 1rem; /* 16px (32px) */
$space-compressed-m: 0.75rem; /* 12px (24px) */
$space-compressed: 0.5rem; /* 8px (16px) */
$space-compressed-s: 0.25rem; /* 4px (8px) */
$space-compressed-xs: 0.125rem; /* 2px (4px) */



/* stretched - 1.5 times the original value */
$space-stretched-uuuul: 15rem; /* 80px (160px) */
$space-stretched-uuul: 8.25rem; /* 132px (88px) */
$space-stretched-uul:  7.5rem; /* 120px (80px) */
$space-stretched-ul: 6.75rem; /* 108px (72px) */
$space-stretched-xxxxl: 6rem; /* 96px (64px) */
$space-stretched-xxxl: 5.25rem; /* 84px (56px) */
$space-stretched-xxl: 4.5rem; /* 72px (48px) */
$space-stretched-xl: 3.75rem; /* 60px (40px) */
$space-stretched-l: 3rem; /* 48px (32px) */
$space-stretched-m: 2.25rem; /* 36px (24px) */
$space-stretched: 1.5rem; /* 24px (16px) */
$space-stretched-s: 0.75rem; /* 12px (8px) */
$space-stretched-xs: 0.375rem; /* 6px (4px) */