@import '../../../src/coreStyles';

.alertModal {
  max-width: 300px;
  min-width: 30px;
  min-height: 30px;
  padding: 6px 46px 6px 16px;
  bottom: 30px;
  top: inherit;
  align-items: center;
  display: inline-flex;
  border-radius: 8px;
  font-size: .875rem;

  &.success {
    background: $colours-primary;
    color: white;
  }
  .classNameCloseButton {
    position: absolute;
    right: 0;
    top: 13px;
    * {
      stroke: white;
    }
    svg {
      width: 10px;
      height: 10px;
    }
    &:after {
      display: none;
    }
    &:hover,
    &:active,
    &:focus {
      background: none;
      cursor: pointer;
    }
  }
}

