@import '../../../coreStyles';

.searchWay {
  padding: 11px 12px 0 36px;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  > div {
    width: 40px;
    &:first-child {
      width: calc(100% - 30px);
    }
  }
  &.lightView {
    background: $colours-primary;
  }
}
.swapIcon {
  position: absolute;
  right: 4px;
  top: 45px;
  display: flex;
  svg {
    position: relative;
    left: 2px;
  }
}

