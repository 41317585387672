@import '../../../coreStyles';

.searchWayFindingButtons {
  margin: -10px 45px 0 40px;
  display: flex;
  justify-content: flex-end;
  position: relative;
  z-index: 11;
  padding: 5px 0 0;
  label {
    display: block;
    margin: 0;
    padding: 0;
    line-height: 1rem;
    font: .85rem/1.5rem var(--global-font-family);
    font-display: swap;
    cursor: pointer;
    &:hover {
      cursor: pointer;
    }
    input[type="checkbox"] + span {
      color: rgb(60, 64, 67);
      &:hover {
        cursor: pointer;
        color: rgb(60, 64, 67);
      }
    }
  }
  &.lightView {
    margin: 0;
    width: calc(100% - 48px);
    label {
      color: white;
      display: inline;
      input[type="checkbox"] {
        border: 1px solid white;
        & + span {
          color: white;
        }
      }
      &.active {
        color: $colours-textDefault;
      }
    }
  }
}
